import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import{HttpClientModule, HttpClient, HttpHeaders} from '@angular/common/http';
import { Navigation , Router} from '@angular/router';
import { ApiServiceService } from 'app/Api/api-service.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";
import { NotifyService } from 'app/Api/notify.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isfrench =true ;
  isArab  ;
  lng : any;
  token : any ;
  user : any;
  password = '';
  rememberedUsername: string = '';
  private toggleButton: any;
    private sidebarVisible: boolean;
  constructor(public http:HttpClient,
    public route: Router,public fb:FormBuilder,
    private apiAccess: ApiServiceService,
    public translate : TranslateService,
    private element : ElementRef,
    public notifyService : NotifyService,
    private renderer : Renderer2
    ) {

        this.sidebarVisible = false;
        this.lng = localStorage.getItem('lng')
        this.translate.use(localStorage.getItem("lng"))
        const savedUsername = localStorage.getItem('rememberedUsername');
    if (savedUsername) {
      this.rememberedUsername = savedUsername; // Set the remembered username if it exists
    }
     }
   
     ngOnInit() {
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
  }

  
  sidebarOpen() {
      const toggleButton = this.toggleButton;
      const html = document.getElementsByTagName('html')[0];
      // console.log(html);
      // console.log(toggleButton, 'toggle');

      setTimeout(function(){
          toggleButton.classList.add('toggled');
      }, 500);
      html.classList.add('nav-open');

      this.sidebarVisible = true;
  };
  sidebarClose() {
      const html = document.getElementsByTagName('html')[0];
      // console.log(html);
      this.toggleButton.classList.remove('toggled');
      this.sidebarVisible = false;
      html.classList.remove('nav-open');
  };
  sidebarToggle() {
      // const toggleButton = this.toggleButton;
      // const body = document.getElementsByTagName('body')[0];
      if (this.sidebarVisible === false) {
          this.sidebarOpen();
      } else {
          this.sidebarClose();
      }
  };

  usercontrolls = this.fb.group({
   username:['',Validators.required],
   password:['',Validators.required],
   //new_password:['',Validators.required],
  });

  async connecter(){

    let login= this.usercontrolls.controls['username'].value.trim(); // get username without space

    let body ={
      'username':login,
      'password':this.usercontrolls.controls['password'].value
    };


   this.password =''
   this.apiAccess.login(body,'auth/login')
   //this.usercontrolls.reset();
    // Save the username to local storage or cookies
    localStorage.setItem('rememberedUsername', this.rememberedUsername);
    console.log("xxxx-yyyy",this.rememberedUsername)

  }
  // langue1(langue :string){
  //    this.translate.use(langue);
  //    localStorage.setItem('lng',langue);
  //    if(langue =='ar'){
  //     this.route.navigateByUrl('/');
  //      document.documentElement.dir ="rtl";

  //    }else{
  //     this.route.navigateByUrl('/');
  //     document.documentElement.dir ="ltr";
  //    }

  // }
  langue1(langue :string){
    console.log(langue)
    this.translate.use(langue);
    if(langue =='ar'){
      localStorage.setItem('lng','fr');
      window.location.reload();
      document.documentElement.dir ="rtl";
      //window.location.reload();
    }else{
     localStorage.setItem('lng','ar');
     window.location.reload();
     document.documentElement.dir ="ltr";
     //window.location.reload();
    } 
}

  getPasswordValue($event){
    let pass=$event.target.value.slice(-1)
    console.log(pass)
    if(String($event.target.value).length < String(this.password).length){
      //console.log("EVENT VALUE 2 ",$event.target.value)
      this.password = String(this.password).substring(0,String($event.target.value).length)
    }else{
      this.password=this.password+String($event.target.value).charAt(String($event.target.value).length -1)
    }
   // this.password=this.password+String($event.target.value).charAt(String($event.target.value).length -1)
   // console.log("PWD = ",$event.target.value +"  \n")
   // console.log("EVENT VALUE ",$event.target.value)

    let d =''
    if(String($event.target.value).length > 0){

      for(let i=0;i<String($event.target.value).length;i++){
        let str =  this.usercontrolls.get('new_password').value
        d=d+'#'
        //console.log("Nouveau format :", d+'\n')

      }
      //console.log(d)

      this.usercontrolls.get('new_password').setValue(d)
    }else{
      this.password =''
    }
    this.usercontrolls.patchValue({
      password:this.password
    })
  }
}
