import { Component, Input, OnInit ,ElementRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiServiceService } from 'app/Api/api-service.service';
import { NotifyService } from 'app/Api/notify.service';
import { TransfertService } from 'app/Api/transfert.service';
import { error } from 'console';
import * as listOfBanks from '../../../../assets/i18n/otherBanks.json';

@Component({
  selector: 'app-other-bank',
  templateUrl: './other-bank.component.html',
  styleUrls: ['./other-bank.component.css']
})
export class OtherBankComponent implements OnInit {
  @Input() user: any;
  @Input() current_manager_agencies:any
  @Input() admin_agencies:any
  @Input() agence:any;
  @Input() privilleges:any
  @Input() rolesInformation:any
  banKControls:FormGroup
  bankPasswordControls:FormGroup
  agencies:any;
  bankLists:any //=[{id:1,bank:"Bim"},{id:2,bank:"bankily"}];
  fermer: string;
  bank_operation_type:string="";
  lang : string;
  banksList: any[];
  banksList2: any[];
  @ViewChild('successModal',{static:true}) successModal:ElementRef;
  constructor(
    private fb:FormBuilder,
    private notifyService:NotifyService,
    private modal: NgbModal,
    public apiService:ApiServiceService,
    public transferService:TransfertService,
    public translate : TranslateService,
    ) {
         this.getBankList()
     }

 

  ngOnInit(): void {
    this.lang = localStorage.getItem('lng');
    this.banksList = listOfBanks.list;

    this.banKControls = this.fb.group({
      amount:['',Validators.required],
      phone:['',[Validators.required,Validators.pattern('^-?[0-9]{8}\\d*(\\.\\d{1,2})?$'),Validators.maxLength(8),Validators.minLength(8)]],
      id_agence:  [2],
      id_user:[''],
      bank:  ['',[Validators.required]],
      bankname:  ['',[]],
      selected_agence_from:[''],
      password:['']
    });
    this.bankPasswordControls= this.fb.group({
      password:['',[Validators.required]],
    });

    console.log("USER ",this.user)
    this.userLogged()
  }

  userLogged(){
    console.log("ROLES INFOS ", this.rolesInformation)
    if (this.rolesInformation.admin==true) {
      this.agencies=this.admin_agencies;
    }
    else if(this.rolesInformation.supervisor || this.rolesInformation.manager){
      this.agencies=this.current_manager_agencies;
      this.banKControls.patchValue({
        id_agence: this.current_manager_agencies[0].id,
        selected_agence_from:this.agencies[0].name+'('+this.agencies[0].code_agence+')'
      })
    }
    
  }
  async getBankList(){
    this.apiService.bimGetOtherBankList().
    subscribe(
      (res:any)=>{
        console.log("RESPONSES",res)
        this.banksList2 = res.bankLists.data;
        // Filter out Bimbank
        this.banksList2 = this.banksList2.filter(item2 => item2.id !== '00015');
        //translate
        if (this.lang =='ar'){
          this.banksList2.forEach(item2 => {
            const matchedBank = this.banksList.find(bank => bank.id === item2.id);
            if (matchedBank) {
              item2.nom = matchedBank.nom;
            }
          });
        }
        
        this.bankLists = this.banksList2;
      },
      (error)=>{
        console.log("ERROR",error)
      },
      ()=>{
        console.log("COMPLETE")
      }
    )
  }
  saveRetraitDemand(){
    this.banKControls.disable()
    this.bankPasswordControls.disable();
    let bank = this.bankLists.find((item)=>(item.id==this.banKControls.get('bank').value)) 
    console.log("BANKS ",this.bankLists)
    console.log("bank ",bank)

    this.banKControls.patchValue({
      id_user:this.user.id,
      bankname:bank.nom,
      password:''+this.bankPasswordControls.get('password').value,
    })
    console.log("demandretrait value ",this.banKControls.value)
    this.apiService.bimSaveDemandRetraitWithOtherBank(this.banKControls.value)
      .subscribe(
        (res:any)=>{
          console.log("RESPONSES",res)
          
  
        },
        (error)=>{
          console.log('error bim bank', error)
          if (error.error.code=='bankoperation_confirm_password_error') {
            this.notifyService.errorNotify("top","center",this.translate.instant('Alert. '+error.error.code))
          }else{
            this.notifyService.errorNotify("top","center",this.translate.instant('Alert. '+error.error.code))
          }
          this.bankPasswordControls.enable()
          this.notifyService.servorErrorNotify(error.status,error)
          this.bankPasswordControls.reset()
  
          this.banKControls.enable();
        },
        ()=>{
          console.log("COMPLETE")
          this.banKControls.reset();
          this.bankPasswordControls.reset()
          this.bankPasswordControls.enable()
          this.banKControls.enable();
         //this.notifyService.successNotify('top','center',this.translate.instant('Message.retrait_success'));
        //this.modal.dismissAll("close")
        
        //this.modal.open(this.successModal);
        this.notifyService.otherbanksNotify('top','center',this.translate.instant('Dashboard.demande_retrait_msg'));
        this.modal.dismissAll("close")
        }
      );
    
  }

  afterSaveRetraitDemand(){
     //this.notifyService.successNotify('top','center',this.translate.instant('Message.retrait_success'));
     this.modal.dismissAll("close")
  }

  depot(){
    this.banKControls.disable();
    this.bankPasswordControls.disable()
    let bank = this.bankLists.find((item)=>(item.id==this.banKControls.get('bank').value)) 
    console.log("BANKS ",this.bankLists)
    console.log("bank ",bank)
    this.banKControls.patchValue({
      bankname:bank.nom,
      password:''+this.bankPasswordControls.get('password').value,
    })

    console.log("Depot Value",this.banKControls.value)
    this.apiService.bimDepotWithOtherBank(this.banKControls.value)
    .subscribe(
      (res:any)=>{
        //console.log("RESPONSES OTHERBANK",res)
        if (res.statusCode!=201) {
          this.notifyService.errorNotify("top","center",this.translate.instant('حساب المستفيد غير موجود أو غير مفعل'));
          this.bankPasswordControls.reset()
          this.banKControls.enable();
          this.bankPasswordControls.enable()

        }else{
          this.notifyService.successNotify('top','center',this.translate.instant('Message.versement_success'))
          this.banKControls.reset();
          this.bankPasswordControls.reset()

          this.banKControls.enable();
          this.bankPasswordControls.enable()
          this.modal.dismissAll("close")
          this.apiService.refreshTransfer(true)
        }
        
      },
      (error)=>{
        console.log("ERROR",error)
        if (error.error.code=='bankoperation_confirm_password_error') {
          this.notifyService.errorNotify("top","center",this.translate.instant('Message.'+error.error.code))
        }else{
          this.notifyService.errorNotify("top","center",this.translate.instant('Message.'+error.error.message))
        }
        this.bankPasswordControls.enable()
        this.bankPasswordControls.reset()

        this.banKControls.enable();
      },
      ()=>{
        
        
      }
    )

  }

  getAgenceFromSelect($event,list,id){
    let userId = (<HTMLInputElement>document.getElementById($event.target.id)).value;

    if (userId.length > 2) {
     // if ($event.timeStamp - this.lastkeydown1 > 200) {
        this.transferService.searchFromArray(list, userId);
      //}
    }
  }

  inputControlPhone(input:  any){
    console.log(input.target.value);
    if(input.target.value.length >= 8 ){
      input.target.value = input.target.value.slice(0,8)
     }
     this.banKControls.patchValue({
      phone:input.target.value
    });
   return;


  }

  openModal(x,clause,action){
      this.bankPasswordControls.reset()

      if(this.rolesInformation.operateur){
        this.banKControls.patchValue({
          id_agence:''+this.agence.id+'',
           
          //beneficiaire:"37486414"
  
        })
  
        console.log("versement Formulaire ",this.banKControls.value)
  
  
  
      }else if((this.rolesInformation.manager || this.rolesInformation.supervisor)){
        if(this.agencies.length == 1){
          this.banKControls.patchValue({
            id_agence:''+this.agencies[0].id+''
          })
        }else{
          let idfrom = this.transferService.searchAgenceById(this.current_manager_agencies,this.banKControls.get('selected_agence_from').value)
          if(idfrom){
            this.banKControls.get('id_agence').setValue(idfrom)
          }else{
  
              this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
              // this.banKControls.get('selected_agence_from').reset()
              this.banKControls.get('selected_agence_from').setValue('')
              return 1
  
          }
        }
      }else{
        let idfrom = this.transferService.searchAgenceById(this.agencies,this.banKControls.get('selected_agence_from').value)
          if(idfrom){
            this.banKControls.get('id_agence').setValue(''+idfrom+'')
          }else{
  
              this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
              this.banKControls.get('selected_agence_from').setValue('')

              return 1
  
          }
      }
      // if(clause =='bank_retrait_confirm_pwd'){
        
      // }
      this.bank_operation_type=action;
      this.modal.open(x,{ariaLabelledBy : 'modal-basic-title',backdrop:'static',keyboard:false,})
      .result.then( (result:any)=>{
          this.fermer = `closed with :${result}`

      },(reason)=>{
        this.fermer = `Dismissed ${this.dism(reason)}`;
        this.bankPasswordControls.reset()
      }
      );
  }
  private dism(reason:any){
    if(reason === ModalDismissReasons.ESC){
        return 'by pressing ESC';
    }else if(reason === ModalDismissReasons.BACKDROP_CLICK){
     return 'by clicking on a backdrop';
    }else{
      return `with : ${reason}`;
    }
 }

}
