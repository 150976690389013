import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class NotifyService {
  public color = Math.floor(Math.random() * 5 + 1);

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  successNotify(from, align, msg) {
    this.color;
    this.toastr.success("<br>" + msg, "", {
      timeOut: 2000,
      closeButton: true,
      enableHtml: true,
      toastClass: "alert alert-success alert-with-icon",
      positionClass: "toast-" + from + "-" + align,
    });
  }

  otherbanksNotify(from, align, msg) {
    this.color;
    this.toastr.success("<br>" + msg, "", {
      timeOut: 120000,
      closeButton: true,
      enableHtml: true,
      toastClass: "alert alert-danger alert-with-icon",
      positionClass: "toast-" + from + "-" + align,
    });
  }

  warningNotify(from, align, msg) {
    this.color;
    this.toastr.warning("<br>" + msg, "", {
      timeOut: 2000,
      closeButton: true,
      enableHtml: true,
      toastClass: "alert alert-warning alert-with-icon",
      positionClass: "toast-" + from + "-" + align,
    });
  }
  infoNotify(from, align, msg) {
    this.color;
    this.toastr.info("<br>" + msg, "", {
      timeOut: 2000,
      closeButton: true,
      enableHtml: true,
      toastClass: "alert alert-info alert-with-icon",
      positionClass: "toast-" + from + "-" + align,
    });
  }
  errorNotify(from, align, msg) {
    this.color;
    this.toastr.error("<br>" + msg, "", {
      timeOut: 4000,
      enableHtml: true,
      closeButton: true,
      toastClass: "alert alert-danger alert-with-icon",
      positionClass: "toast-" + from + "-" + align,
    });
  }

  servorErrorNotify(status, error) {
    let code = "servor_error";
    if (error.error.code) {
      code = error.error.code;
    }
    if (status == 1) {
      this.errorNotify(
        "top",
        "center",
        this.translate.instant("Message.access_servor_error")
      );
    } else if (status == 403) {
      this.errorNotify(
        "top",
        "center",
        this.translate.instant("Message.not_authorized")
      );
    } else if (status == 500) {
      if (error.error.code) {
        this.errorNotify(
          "top",
          "center",
          this.translate.instant("Message." + error.error.code)
        );
      }
    } else if (error.error.code == "plafond_agence_exceded") {
      this.errorNotify(
        "top",
        "center",
        this.translate.instant("Message." + error.error.code)
      );
    }
  }

  getManagerFeesInTransfert(transfert, managerId) {
    /* if(transfert.status != 'ended' || transfert.type_transfert == 'normal' ){
       return "0";
     }else if(transfert.type_transfert != 'normal'){
       return "0";
     }
     let parcours =transfert.parcours
     let distribution_frais = transfert.distributions
     let index_frais = parcours.indexOf(this.managerId)
     console.log("Index of 347 ",parcours.indexOf(this.managerId));
     console.log("la distributions ",distribution_frais[index_frais])
     return distribution_frais[index_frais]
     */
    if (
      transfert.status == "ended" &&
      (transfert.type_transfert == "normal" ||
        transfert.type_transfert == "paiement_facture")
    ) {
      let parcours = JSON.parse(transfert.parcours);
      let distribution_frais = JSON.parse(transfert.distributions);
      let index_frais = parcours.indexOf(managerId);
      console.log("Index of  ", parcours.indexOf(managerId));
      console.log("distr ", distribution_frais);
      console.log("la distributions ", distribution_frais[index_frais]);
      return distribution_frais[index_frais];
    } else {
      return "0";
    }
  }
}
